<!-- eslint-disable max-len -->
<template>
  <svg viewBox="0 0 676 674" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M193.553 299.58L117.331 169.696H154.64C229.343 169.696 239.418 203.589 239.418 236.736C239.418 272.228 225.559 291.525 193.553 299.54M310.933 236.736C310.933 187.524 290.637 105 154.64 105H0.671387L116.606 303.796H13.1953V568.162H84.7049V368.502H154.228L269.438 568.162H349.692L227.629 357.875C281.559 339.501 310.918 297.206 310.918 236.776"
      fill="#008F99"
    />
    <path
      d="M374.81 302.333V568.01H446.816V366.89H546.134C617.442 366.89 675.248 307.842 675.248 236.534C675.248 165.227 617.442 106.179 546.134 106.179H374.81V170.736H541.168C574.08 170.736 600.759 197.416 600.759 230.327V242.742C600.759 275.653 574.08 302.333 541.168 302.333H374.81Z"
      fill="#008F99"
    />
  </svg>
</template>
